<template>
    <div class="p-2 mt-4">
        <div v-if="isLoading">
            <div style="min-height: 300px" class="d-flex align-items-center justify-content-center flex-column">

                <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
                <h6 class="text-body mt-3">Loading</h6>

            </div>
        </div>
        <div v-else>
            <div class="p-2 px-4">
                <h4>FlightPath setup : {{ this.$store.state.user.organisation.flight_path_step.name }}</h4>
                <div class="card border rounded p-3 mt-2">

                    <div class="px-2">

                        <div class="mb-3 font-weight-bold">
                            {{ this.$store.state.user.organisation.flight_path_step.title }}
                        </div>

                        <div class="mb-2" v-for="check in this.$store.state.user.organisation.flight_path_step.checks">
                            <div class="d-flex align-items-center">
                                <b-badge variant="info" v-if="check.check == null">
                                    Please confirm
                                </b-badge>
                                <b-badge :variant="check.completed ? 'success' : 'danger'"
                                         v-else-if="check.is_required">
                                    {{ check.completed ? "Complete" : "Error" }}
                                </b-badge>
                                <b-badge :variant="check.completed ? 'success' : 'warning'" v-else>
                                    {{ check.completed ? "Complete" : "Warning" }}
                                </b-badge>

                                <div class="ml-2">
                                    {{ check.completed ? check.complete_note : check.incomplete_note }}
                                    <a v-if="check.link != null" target="_blank" :href="check.link">
                                        - configure now
                                    </a>
                                </div>
                            </div>
                            <div v-if="check.sublist !== undefined" class="ml-3 pt-2 font">
                                <div class="ml-1" v-for="sub in check.sublist">
                                    <a target="_blank" :href="sub.link">
                                        {{ sub.title }}
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div v-if="warningCount() > 0" class="mt-3 font-weight-bold">
                            Before proceeding you may wish to review the
                            <b-badge variant="warning">
                                Warning
                            </b-badge>
                            above
                        </div>

                        <div v-if="errorCount() > 0" class="mt-3 font-weight-bold">
                            Before proceeding you need to address the
                            <b-badge variant="danger">
                                Error{{ errorCount() > 1 ? "s" :"" }}
                            </b-badge>
                            above
                        </div>

                        <div v-if="!$store.getters.isAdmin && $store.getters.canBeAdmin" class="alert alert-danger mt-4">
                            Please make sure the <strong>act as admin</strong> toggle is enabled to confirm and sumbit this stage
                        </div>

                        <div v-if="errorCount() === 0 && this.$store.getters.isAdmin">

                            <div class="alert alert-warning border mt-3">


                                <div v-if="this.$store.state.user.organisation.flight_path_step.on_submit != null"
                                     >
                                    <div class="font-italic mb-2">Ticking the box below & submitting the form will:
                                    </div>
                                    <div v-html="$store.state.user.organisation.flight_path_step.on_submit"
                                         class="font-weight-bold">
                                        {{ this.$store.state.user.organisation.flight_path_step.on_submit }}
                                    </div>
                                </div>

                                <b-form-group class="mb-0 mt-2">
                                    <b-form-group>
                                        <b-form-checkbox
                                                v-model="confirmed"
                                                :unchecked-value="false"
                                                :checked-value="true"
                                        ><strong>Confirm</strong>
                                        </b-form-checkbox>
                                    </b-form-group>
                                </b-form-group>
                            </div>



                            <b-form-group class="mb-0">
                                <b-button :disabled="!confirmed" @click="nextStep" variant="primary">Submit</b-button>
                            </b-form-group>
                        </div>

                    </div>

                </div>

            </div>
        </div>
    </div>
</template>
<script>
    import PaginatedResultLoader from "../../components/PaginatedResultLoader";
    import {getResource} from "../../modules/api/methods";
    import {applicationStats, flightpathConfirm} from "../../modules/api/endpoints";
    import _ from 'lodash'

    export default {
        components: {PaginatedResultLoader},
        name: "DashboardHome",
        data: function () {
            return {
                confirmed: false,
                isLoading: false,
                stats: {}
            }
        },
        created() {

        },
        methods: {
            warningCount() {
                var count = 0;
                _.each(this.$store.state.user.organisation.flight_path_step.checks, function (check) {
                    if (check.check != null && !check.is_required && check.completed === false) {
                        count++
                    }
                });

                return count;
            },
            errorCount() {
                var count = 0;
                _.each(this.$store.state.user.organisation.flight_path_step.checks, function (check) {
                    if (check.check != null && check.is_required && check.completed === false) {
                        count++
                    }
                });
                return count;
            },
            nextStep() {
                this.isLoading = true;
                this.confirmed = false;
                getResource(flightpathConfirm).then((resp) => {
                    console.log("RESPONSE IS", resp.data.success);
                    if (resp.data.success !== undefined) {
                        this.$store.commit("updateUser", resp.data.success)
                    } else {
                        this.$root.$children[0].handleInvalidResponse(resp, this.nextStep);
                    }
                }).catch((ex) => {
                    console.log(ex);
                    this.$root.$children[0].handleApiError(ex, this.nextStep);
                }).finally(() => {
                    this.isLoading = false;
                });
            }
        }
    }
</script>
